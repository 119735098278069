<template>
  <v-card>
    <v-card-title class="justify-space-between text-h5 font-weight-medium">
      <span>
        {{ currentSkill?.getTitle() ?? 'Loading ...' }}
      </span>
      <v-btn icon @click="closeModalChild">
        <v-icon>
          {{ mdiClose }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-card v-if="error">
        <v-alert type="error" dismissible>
          {{ error }}
        </v-alert>
      </v-card>
      <span v-else>
        <v-tabs v-model="activeTab" centered height="100%">
          <v-tab href="#about" class="text-capitalize pa-3">
            <v-icon left class="mr-2">{{ mdiInformationOutline }}</v-icon>
            ABOUT
          </v-tab>
          <v-tab href="#questions" class="text-capitalize pa-3">
            <v-icon left class="mr-2">{{ mdiFrequentlyAskedQuestions }}</v-icon>
            QUESTIONS
          </v-tab>
        </v-tabs>
        <v-tabs-items :value="activeTab">
          <v-tab-item value="about">
            <v-card rounded="xl" outlined :loading="loading">
              <v-card-text>
                <v-row>
                  <v-col cols="5">
                    <div class="pt-5">
                      <div class="black--text text-body-1 font-weight-medium">
                        <v-icon color="black">
                          {{ mdiInformationOutline }}
                        </v-icon>
                        Summary
                      </div>
                      <div class="pb-8">
                        {{ summary ?? '' }}
                      </div>
                    </div>
                    <div class="d-flex">
                      <v-row no-gutters>
                        <v-col cols="4">
                          <div
                            class="black--text text-body-1 font-weight-medium"
                          >
                            <v-icon color="black">
                              {{ mdiClockOutline }}
                            </v-icon>
                            Time
                          </div>
                          <div class="pb-8">
                            {{
                              displayTime(
                                this.currentSkill
                                  ?.getMetaData()
                                  ?.getTimeLimit(),
                              ) ?? 'Loading ...'
                            }}
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <div
                            class="black--text text-body-1 font-weight-medium"
                          >
                            <v-icon color="black">
                              {{ mdiSignal }}
                            </v-icon>
                            Level
                          </div>
                          <div class="pb-8">
                            {{ currrentSkillLevel ?? 'Loading ...' }}
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <div
                            class="black--text text-body-1 font-weight-medium"
                          >
                            <v-icon color="black">
                              {{ languageIcon }}
                            </v-icon>
                            Language
                          </div>
                          <div class="pb-8">
                            {{ showLanguage }}
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>

                  <v-col cols="7" class="fill-height">
                    <div class="pt-5">
                      <div class="black--text text-body-1 font-weight-medium">
                        Description
                      </div>
                      <div
                        class="contents"
                        v-html="skillDescription ?? 'Loading ...'"
                      ></div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-if="expertDetailsHtml">
                <div class="black--text text-body-1 font-weight-medium">
                  About the Expert
                </div>
                <v-row class="pt-5">
                  <v-col cols="3">
                    <v-img class="expert-img" :src="expertImageUrl"> </v-img>
                  </v-col>
                  <v-col cols="8">
                    <div class="contents" v-html="expertDetailsHtml"></div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="questions">
            <SkillQuestionView
              v-for="question in questions"
              :key="question.getContentPreview().getId()"
              :question="question.getContentPreview()"
              :skillId="skill.id"
            />
          </v-tab-item>
        </v-tabs-items>
      </span>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import {
  mdiClockOutline,
  mdiSignal,
  mdiInformationOutline,
  mdiFrequentlyAskedQuestions,
} from '@mdi/js'
import formattedTimeMixin from '@/mixins/formattedTimeMixin'
import SkillQuestionView from './SkillQuestionView.vue'
import {
  mdiWeb,
  mdiLanguagePython,
  mdiLanguageJava,
  mdiLanguageKotlin,
  mdiLanguageCpp,
  mdiLanguageGo,
  mdiLanguageCsharp,
  mdiLanguageRuby,
  mdiLanguageJavascript,
  mdiLanguageTypescript,
  mdiLanguagePhp,
  mdiClose,
} from '@mdi/js'
export default {
  props: {
    skill: {
      type: Object,
      required: true,
    },
  },
  mixins: [formattedTimeMixin],
  data() {
    return {
      mdiInformationOutline,
      mdiClockOutline,
      mdiSignal,
      mdiLanguageKotlin,
      mdiLanguageCpp,
      mdiLanguageJava,
      mdiLanguagePython,
      mdiLanguageGo,
      mdiLanguageCsharp,
      mdiLanguageRuby,
      mdiLanguageJavascript,
      mdiLanguageTypescript,
      mdiLanguagePhp,
      mdiWeb,
      mdiClose,
      mdiFrequentlyAskedQuestions,

      activeTab: 'about',
      error: null,
      loading: true,
    }
  },
  computed: {
    ...mapState('recruiter', [
      'currentSkill',
      'currentSkillStatus',
      'currentSkillStatus',
    ]),
    ...mapGetters('recruiter', ['skillLevelKeys', 'prettySkillLevels']),
    skillDescription() {
      return (
        this.currentSkill
          ?.getDataView()
          ?.getSkillView()
          ?.getDescriptionHtml() || 'No Description'
      )
    },
    questions() {
      return this.currentSkill?.getChildPreviewsList() || []
    },
    expertDetailsHtml() {
      return this.currentSkill
        ?.getDataView()
        ?.getSkillView()
        ?.getExpertDetailsHtml()
    },

    // displayTime() {
    //   const timeInS = this.currentSkill?.getMetaData()?.getTimeLimit()

    //   return moment.duration(timeInS / 60, 'minutes').humanize()
    // },

    languageIcon() {
      const languageIconMap = {
        0: this.mdiWeb,
        1: this.mdiLanguageCpp,
        2: this.mdiLanguageJava,
        3: this.mdiLanguagePython,
        4: this.mdiLanguageKotlin,
        6: this.mdiLanguageJavascript,
        7: this.mdiLanguageRuby,
        8: this.mdiLanguageCsharp,
        9: this.mdiLanguageTypescript,
        10: this.mdiLanguageGo,
        13: this.mdiLanguagePhp,
      }

      let allowedLanguages =
        this.currentSkill
          ?.getDataView()
          ?.getSkillView()
          ?.getLanguageRestriction()
          ?.getAllowLanguageList() || []

      if (allowedLanguages.length === 1) {
        var langIcon = languageIconMap[allowedLanguages[0]]
        if (langIcon != undefined) return langIcon
      }

      return this.mdiWeb
    },

    showLanguage() {
      const languageMap = {
        0: 'Text',
        1: 'C++',
        2: 'Java',
        3: 'Python',
        4: 'Kotlin',
        5: 'MySQL',
        6: 'JavaScript',
        7: 'Ruby',
        8: 'C#',
        9: 'TypeScript',
        10: 'Go',
        11: 'Dart',
        12: 'R',
        13: 'PHP',
      }
      let allowedLanguages =
        this.currentSkill
          ?.getDataView()
          ?.getSkillView()
          ?.getLanguageRestriction()
          ?.getAllowLanguageList() || []

      let numLangs = allowedLanguages.length
      var langStr = ''
      for (let i = 0; i < numLangs; i++) {
        langStr += languageMap[allowedLanguages[i]]
        if (i != numLangs - 1) langStr += ' '
      }

      if (numLangs == 0) return 'Any Language'

      return langStr
    },

    currrentSkillLevel() {
      const num = this.currentSkill
        ?.getMetaData()
        ?.getSkillMeta()
        ?.getSkillLevel()
      return this.prettySkillLevels[this.skillLevelKeys[num]]
    },

    summary() {
      return this.skill.metaData?.skillMeta?.summary || 'No summary'
    },

    expertImageUrl() {
      return (
        this.currentSkill?.getDataView()?.getSkillView()?.getExpertImageUrl() ||
        'https://media.istockphoto.com/photos/portrait-of-young-businessman-standing-in-his-office-with-arms-picture-id1193290387?s=612x612'
      )
    },
  },
  methods: {
    ...mapActions('recruiter', ['fetchSkill']),
    ...mapMutations('recruiter', ['fetchSkillStatus', 'setCurrentSkill']),
    closeModalChild() {
      this.$emit('closemodal')
    },
  },
  async mounted() {
    this.loading = true
    this.error = null
    this.fetchSkill({
      id: this.skill.id,
    })
      .catch((error) => {
        this.error = error.message
      })
      .finally(() => {
        console.log('finally')
        this.loading = false
      })
  },
  destroyed() {
    console.log('destroyed')
    this.fetchSkillStatus(null)
    this.setCurrentSkill(null)
  },
  components: {
    SkillQuestionView,
  },
}
</script>
<style scoped>
.expert-img {
  border-radius: 50%;
  height: 200px;
  width: 200px;
}
.v-tab {
  letter-spacing: 1px;
  min-width: 100px;
  font-weight: 500;
}
.v-tab--active {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
