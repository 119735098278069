var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_vm.error ? _c('v-alert', {
    staticClass: "mb-4",
    attrs: {
      "type": "error"
    }
  }, [_vm._v(" " + _vm._s(_vm.error) + " "), _c('v-btn', {
    attrs: {
      "color": "white",
      "text": ""
    },
    on: {
      "click": _vm.tryFetchQuestion
    }
  }, [_vm._v(" Retry ")])], 1) : _vm.loading ? _c('v-sheet', {
    staticClass: "pa-3"
  }, [_c('v-progress-linear', {
    staticClass: "mb-3",
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  }), _vm._l(3, function (i) {
    return _c('v-skeleton-loader', {
      key: i,
      staticClass: "mb-2",
      attrs: {
        "type": "list-item-two-line"
      }
    });
  })], 2) : _vm.questionView ? [_c('div', {
    staticClass: "tag-container"
  }, [_vm._l(_vm.topics, function (topic) {
    return _c('v-chip', {
      key: 'topic-' + topic,
      staticClass: "mr-2 mb-2",
      attrs: {
        "color": "primary",
        "text-color": "white",
        "small": ""
      }
    }, [_c('v-icon', {
      attrs: {
        "left": "",
        "small": ""
      }
    }, [_vm._v(_vm._s(_vm.mdiTag))]), _vm._v(" " + _vm._s(topic) + " ")], 1);
  }), _vm._l(_vm.difficulties, function (difficulty) {
    return _c('v-chip', {
      key: 'diff-' + difficulty,
      staticClass: "mr-2 mb-2",
      attrs: {
        "color": "primary",
        "text-color": "white",
        "small": ""
      }
    }, [_c('v-icon', {
      attrs: {
        "left": "",
        "small": ""
      }
    }, [_vm._v(_vm._s(_vm.mdiStairs))]), _vm._v(" " + _vm._s(difficulty) + " ")], 1);
  })], 2), _vm.questionView.getDataView().getSubjectiveView() ? _c('v-card', {
    staticClass: "mb-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v(" Subjective Question "), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "color": "primary"
          }
        }, 'v-icon', attrs, false), on), [_vm._v(" " + _vm._s(_vm.mdiHelpCircleOutline) + " ")])];
      }
    }], null, false, 2419380785)
  }, [_c('span', [_vm._v("Subjective questions require a written response based on candidate's understanding and opinion. It needs to be marked manually")])])], 1), _c('v-card-text', [_c('html-view', {
    attrs: {
      "html": _vm.questionView.getDataView().getSubjectiveView().getDescriptionHtml()
    }
  })], 1)], 1) : _vm.questionView.getDataView().getMcqView() ? _c('v-card', {
    staticClass: "mb-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v(" Multiple Choice Question (MCQ) "), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "color": "primary"
          }
        }, 'v-icon', attrs, false), on), [_vm._v(" " + _vm._s(_vm.mdiHelpCircleOutline) + " ")])];
      }
    }])
  }, [_c('span', [_vm._v("Multiple Choice Questions test candidate's knowledge quickly by asking to select one or more answers from the given options. MCQs are scored automatically.")])])], 1), _c('v-card-text', [_c('html-view', {
    attrs: {
      "html": _vm.questionView.getDataView().getMcqView().getStatementHtml()
    }
  }), _vm._l(_vm.questionView.getDataView().getMcqView().getOptionList(), function (option, index) {
    return _c('v-card', {
      key: index,
      staticClass: "mb-2",
      attrs: {
        "dense": "",
        "outlined": ""
      }
    }, [_c('v-card-text', [_c('v-checkbox', {
      attrs: {
        "dense": "",
        "disabled": ""
      },
      scopedSlots: _vm._u([{
        key: "label",
        fn: function () {
          return [_c('div', {}, [_c('html-view', {
            attrs: {
              "html": option.getStatementHtml()
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    })], 1)], 1);
  })], 2)], 1) : _vm.questionView.getDataView().getProblemView() ? _c('v-card', {
    staticClass: "mb-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v(" Coding Problem "), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "color": "primary"
          }
        }, 'v-icon', attrs, false), on), [_vm._v(" " + _vm._s(_vm.mdiHelpCircleOutline) + " ")])];
      }
    }])
  }, [_c('span', [_vm._v("A coding question requires the candidate to write code that runs against hidden judge test cases. It is scored automatically by our coding judge system.")])])], 1), _c('v-card-text', [_c('html-view', {
    attrs: {
      "html": _vm.questionView.getDataView().getProblemView().getStatementView()
    }
  })], 1)], 1) : _vm._e()] : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }