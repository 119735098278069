<template>
  <v-card flat>
    <v-card-text>
      <v-alert v-if="error" type="error" class="mb-4">
        {{ error }}
        <v-btn color="white" text @click="tryFetchQuestion"> Retry </v-btn>
      </v-alert>

      <v-sheet v-else-if="loading" class="pa-3">
        <v-progress-linear
          indeterminate
          color="primary"
          class="mb-3"
        ></v-progress-linear>
        <v-skeleton-loader
          v-for="i in 3"
          :key="i"
          type="list-item-two-line"
          class="mb-2"
        ></v-skeleton-loader>
      </v-sheet>

      <template v-else-if="questionView">
        <div class="tag-container">
          <v-chip
            v-for="topic in topics"
            :key="'topic-' + topic"
            class="mr-2 mb-2"
            color="primary"
            text-color="white"
            small
          >
            <v-icon left small>{{ mdiTag }}</v-icon>
            {{ topic }}
          </v-chip>
          <v-chip
            v-for="difficulty in difficulties"
            :key="'diff-' + difficulty"
            class="mr-2 mb-2"
            color="primary"
            text-color="white"
            small
          >
            <v-icon left small>{{ mdiStairs }}</v-icon>
            {{ difficulty }}
          </v-chip>
        </div>
        <v-card
          v-if="questionView.getDataView().getSubjectiveView()"
          class="mb-4"
        >
          <v-card-title class="subtitle-1 font-weight-bold">
            Subjective Question
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2"
                >
                  {{ mdiHelpCircleOutline }}
                </v-icon>
              </template>
              <span
                >Subjective questions require a written response based on
                candidate's understanding and opinion. It needs to be marked
                manually</span
              >
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <html-view
              :html="
                questionView
                  .getDataView()
                  .getSubjectiveView()
                  .getDescriptionHtml()
              "
            ></html-view>
          </v-card-text>
        </v-card>
        <v-card
          v-else-if="questionView.getDataView().getMcqView()"
          class="mb-4"
        >
          <v-card-title class="subtitle-1 font-weight-bold">
            Multiple Choice Question (MCQ)
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2"
                >
                  {{ mdiHelpCircleOutline }}
                </v-icon>
              </template>
              <span
                >Multiple Choice Questions test candidate's knowledge quickly by
                asking to select one or more answers from the given options.
                MCQs are scored automatically.</span
              >
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <html-view
              :html="questionView.getDataView().getMcqView().getStatementHtml()"
            ></html-view>
            <v-card
              dense
              outlined
              v-for="(option, index) in questionView
                .getDataView()
                .getMcqView()
                .getOptionList()"
              :key="index"
              class="mb-2"
            >
              <v-card-text>
                <v-checkbox dense disabled>
                  <template v-slot:label>
                    <div class="">
                      <html-view :html="option.getStatementHtml()"></html-view>
                    </div>
                  </template>
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
        <v-card
          v-else-if="questionView.getDataView().getProblemView()"
          class="mb-4"
        >
          <v-card-title class="subtitle-1 font-weight-bold">
            Coding Problem
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2"
                >
                  {{ mdiHelpCircleOutline }}
                </v-icon>
              </template>
              <span
                >A coding question requires the candidate to write code that
                runs against hidden judge test cases. It is scored automatically
                by our coding judge system.</span
              >
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <html-view
              :html="
                questionView.getDataView().getProblemView().getStatementView()
              "
            ></html-view>
          </v-card-text>
        </v-card>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { mdiHelpCircleOutline, mdiTag, mdiStairs } from '@mdi/js'
import HtmlView from '@/components/HtmlView.vue'
export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
    skillId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      mdiHelpCircleOutline,
      mdiTag,
      mdiStairs,

      error: null,
      loading: true,
      questionView: null,
    }
  },
  methods: {
    ...mapActions('recruiter', ['fetchQuestion']),
    tryFetchQuestion() {
      this.error = null
      this.loading = true
      return this.fetchQuestion({
        skillId: this.skillId,
        questionId: this.question?.getId(),
      })
        .then((question) => {
          console.log('question', question)
          this.questionView = question.getContentView()
        })
        .catch((error) => {
          this.error = error.message
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  computed: {
    tags() {
      return this.questionView?.getMetaData()?.getTagsList() || []
    },
    topics() {
      return this.tags
        .filter((tag) => tag.startsWith('topics/'))
        .map((tag) => tag.split('/')[1])
    },
    difficulties() {
      return this.tags
        .filter((tag) => tag.startsWith('difficulties/'))
        .map((tag) => tag.split('/')[1])
    },
  },
  mounted() {
    this.tryFetchQuestion()
  },
  components: {
    HtmlView,
  },
}
</script>

<style scoped>
/* Add your component-specific styles here */
</style>
