var render = function render(){
  var _vm$currentSkill$getT, _vm$currentSkill, _vm$summary, _vm$displayTime, _this$currentSkill, _this$currentSkill$ge, _vm$currrentSkillLeve, _vm$skillDescription;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', {
    staticClass: "justify-space-between text-h5 font-weight-medium"
  }, [_c('span', [_vm._v(" " + _vm._s((_vm$currentSkill$getT = (_vm$currentSkill = _vm.currentSkill) === null || _vm$currentSkill === void 0 ? void 0 : _vm$currentSkill.getTitle()) !== null && _vm$currentSkill$getT !== void 0 ? _vm$currentSkill$getT : 'Loading ...') + " ")]), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeModalChild
    }
  }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.mdiClose) + " ")])], 1)], 1), _c('v-card-text', [_vm.error ? _c('v-card', [_c('v-alert', {
    attrs: {
      "type": "error",
      "dismissible": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")])], 1) : _c('span', [_c('v-tabs', {
    attrs: {
      "centered": "",
      "height": "100%"
    },
    model: {
      value: _vm.activeTab,
      callback: function ($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('v-tab', {
    staticClass: "text-capitalize pa-3",
    attrs: {
      "href": "#about"
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "left": ""
    }
  }, [_vm._v(_vm._s(_vm.mdiInformationOutline))]), _vm._v(" ABOUT ")], 1), _c('v-tab', {
    staticClass: "text-capitalize pa-3",
    attrs: {
      "href": "#questions"
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "left": ""
    }
  }, [_vm._v(_vm._s(_vm.mdiFrequentlyAskedQuestions))]), _vm._v(" QUESTIONS ")], 1)], 1), _c('v-tabs-items', {
    attrs: {
      "value": _vm.activeTab
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "about"
    }
  }, [_c('v-card', {
    attrs: {
      "rounded": "xl",
      "outlined": "",
      "loading": _vm.loading
    }
  }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('div', {
    staticClass: "pt-5"
  }, [_c('div', {
    staticClass: "black--text text-body-1 font-weight-medium"
  }, [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiInformationOutline) + " ")]), _vm._v(" Summary ")], 1), _c('div', {
    staticClass: "pb-8"
  }, [_vm._v(" " + _vm._s((_vm$summary = _vm.summary) !== null && _vm$summary !== void 0 ? _vm$summary : '') + " ")])]), _c('div', {
    staticClass: "d-flex"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "black--text text-body-1 font-weight-medium"
  }, [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiClockOutline) + " ")]), _vm._v(" Time ")], 1), _c('div', {
    staticClass: "pb-8"
  }, [_vm._v(" " + _vm._s((_vm$displayTime = _vm.displayTime((_this$currentSkill = this.currentSkill) === null || _this$currentSkill === void 0 ? void 0 : (_this$currentSkill$ge = _this$currentSkill.getMetaData()) === null || _this$currentSkill$ge === void 0 ? void 0 : _this$currentSkill$ge.getTimeLimit())) !== null && _vm$displayTime !== void 0 ? _vm$displayTime : 'Loading ...') + " ")])]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "black--text text-body-1 font-weight-medium"
  }, [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiSignal) + " ")]), _vm._v(" Level ")], 1), _c('div', {
    staticClass: "pb-8"
  }, [_vm._v(" " + _vm._s((_vm$currrentSkillLeve = _vm.currrentSkillLevel) !== null && _vm$currrentSkillLeve !== void 0 ? _vm$currrentSkillLeve : 'Loading ...') + " ")])]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "black--text text-body-1 font-weight-medium"
  }, [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v(" " + _vm._s(_vm.languageIcon) + " ")]), _vm._v(" Language ")], 1), _c('div', {
    staticClass: "pb-8"
  }, [_vm._v(" " + _vm._s(_vm.showLanguage) + " ")])])], 1)], 1)]), _c('v-col', {
    staticClass: "fill-height",
    attrs: {
      "cols": "7"
    }
  }, [_c('div', {
    staticClass: "pt-5"
  }, [_c('div', {
    staticClass: "black--text text-body-1 font-weight-medium"
  }, [_vm._v(" Description ")]), _c('div', {
    staticClass: "contents",
    domProps: {
      "innerHTML": _vm._s((_vm$skillDescription = _vm.skillDescription) !== null && _vm$skillDescription !== void 0 ? _vm$skillDescription : 'Loading ...')
    }
  })])])], 1)], 1), _vm.expertDetailsHtml ? _c('v-card-text', [_c('div', {
    staticClass: "black--text text-body-1 font-weight-medium"
  }, [_vm._v(" About the Expert ")]), _c('v-row', {
    staticClass: "pt-5"
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-img', {
    staticClass: "expert-img",
    attrs: {
      "src": _vm.expertImageUrl
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('div', {
    staticClass: "contents",
    domProps: {
      "innerHTML": _vm._s(_vm.expertDetailsHtml)
    }
  })])], 1)], 1) : _vm._e()], 1)], 1), _c('v-tab-item', {
    attrs: {
      "value": "questions"
    }
  }, _vm._l(_vm.questions, function (question) {
    return _c('SkillQuestionView', {
      key: question.getContentPreview().getId(),
      attrs: {
        "question": question.getContentPreview(),
        "skillId": _vm.skill.id
      }
    });
  }), 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }